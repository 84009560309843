import React, { useState, useEffect } from 'react';
import { useSearchLabelsManual } from 'hooks/useSearchLabels';
import AnswerLabel from 'components/AnswerSection/labels/AnswerLabel';
import { SettingsIcon } from 'components/utils/iconComponents';
import { useNavigate } from 'react-router-dom';

const OrderLabel = ({ handlerLabelAnswer, orderInfo }) => {
  //labels
  const [labels, setLabels] = useState([]);
  const [labelError, setLabelError] = useState();
  const navigate = useNavigate();

  const {
    searchLabels,
    loading: loadingSearchLabels,
    error: errorSearchLabels,
  } = useSearchLabelsManual();

  useEffect(() => {
    const fetchDataLabels = async () => {
      const { data } = await searchLabels({
        variables: {
          query: '',
          module: 'ordenes',
          limit: 20,
          offset: 0,
        },
      });

      if (errorSearchLabels) {
        let error = 'Error';
        if (errorSearchLabels.httpError) {
          error = `${errorSearchLabels.httpError.statusText} - para obtener las etiquetas`;
        }

        setLabelError(error);
      }

      if (data) {
        setLabels(data.searchLabels.labels);
      }
    };
    fetchDataLabels();
  }, []);

  const handlerClickAjustes = () => {
    navigate('/etiquetas');
  };

  return (
    <div>
      <div className='w-[45px] h-[45px] rounded-xl p-2 text-gray-500 cursor-pointer fixed right-[30px]' onClick={handlerClickAjustes}>
        <SettingsIcon />
      </div>
      {labels.length > 0 && orderInfo ? (
        <AnswerLabel
          labels={labels}
          handlerLabelAnswer={handlerLabelAnswer}
          elementId=''
          isFromOrders={true}
        />
      ) : (
        <div className='mb-1 h-24 w-full p-4 rounded-xl shadow border-2 border-gray-100 overflow-y-auto flex flex-wrap text-gray-500 bg-white'>
          No hay etiquetas configuradas para esta cuenta
        </div>
      )}
    </div>
  );
};

export default OrderLabel;
