import React, { useEffect, useState } from 'react';
import { MLFormField } from 'components/MLForm/MLFormField';
import { ML_FORM_TYPES } from 'components/MLForm/types/MLForm.types';
import { Control } from 'react-hook-form';
import { memoize } from 'lodash';
import { useGetSelectedProductAttributesValuesManual } from 'hooks/useGetSelectedProductAttributesValues';
import { useGetGridOptionsManual } from 'hooks/useGetGridOptions';
import axios from 'axios';

function processComponents(comps: []) {
  const processed = comps
    ?.reduce((prev: any, curr: any) => {
      if (curr.component === ML_FORM_TYPES.LINKED_BY_CONNECTOR_INPUT) {
        console.log('>> curr: ', curr);
        const innerComponents = curr.attributes.map((attr: any) => ({
          component: 'NUMBER_UNIT_INPUT',
          label: `${attr.name} (${attr.default_unit_id})`,
          ui_config: {
            allow_custom_value: false,
            allow_filtering: false,
          },
          attributes: [
            {
              id: attr.id,
              name: attr.name,
              value_type: 'number_unit',
              value_max_length: 255,
              tags: attr.tags,
              default_unit_id: attr.default_unit_id,
              units: [
                {
                  id: attr.default_unit_id,
                  name: attr.default_unit_id,
                },
              ],
              hierarchy: 'FAMILY',
              relevance: 1,
            },
          ],
          default_unified_unit_id: attr.default_unit_id,
          unified_units: [
            {
              id: attr.default_unit_id,
              name: attr.default_unit_id,
            },
          ],
        }));

        return [...prev, ...innerComponents];
      } else if (curr.component === ML_FORM_TYPES.BOOLEAN_INPUT) {
        return [
          ...prev,
          {
            ...curr,
            label: `${curr.attributes[0].name} (si/no)`,
          },
        ];
      } else if (
        curr.component === ML_FORM_TYPES.COMBO &&
        !curr.ui_config?.allow_custom_value
      ) {
        return [
          ...prev,
          {
            ...curr,
            label: `${curr.attributes[0].name} (${curr.attributes
              .map((attr: any) => attr.name)
              .join('/')})`,
          },
        ];
      } else {
        return [...prev, curr];
      }
    }, [])
    .filter(Boolean)
    .map((comp: any) => {
      if (comp.component === ML_FORM_TYPES.LINKED_BY_CONNECTOR_INPUT) {
        return {
          ...comp,
        };
      }

      const compAttrs = comp.attributes[0];
      const compTags = compAttrs.tags;
      // check where to add hidden components to send
      if (compTags.includes('hidden')) {
        return false;
      }
      return {
        ...comp,
        attributes: compAttrs,
        label: comp.label,
      };
    })
    .filter(Boolean);
  return processed;
}

function processGroup(group: any) {
  const groupComponents = processComponents(group.components);
  if (!groupComponents.length) {
    return;
  }
  // console.log(">> group all: ", group);
  const groupObj = {
    id: group.id,
    label: group.label,
    components: groupComponents,
  };
  return groupObj;
}

function AddManualProductFormAttributes({
  categoryId,
  control,
  attrSections,
  setAttrSections,
  attributeValues = [],
  attrComponents = [],
  resp = [],
  getValues,
  asin,
  itemId = '',
}: {
  categoryId: string | undefined;
  control: Control<any>;
  attrSections: any;
  setAttrSections: (attrs: any[]) => void;
  attributeValues?: { name: string; value: string | number }[];
  attrComponents?: any[];
  resp?: any;
  getValues: (name: string) => any;
  asin?: string;
  itemId?: string;
}) {
  const {
    getSelectedProductAttributesValues,
    loading: loadingAttributes,
    error: errorLoadingAttributes,
    data: selectedProductAttributesValuesData,
  } = useGetSelectedProductAttributesValuesManual();
  const { getGridOptions, data: gridOptionsData } = useGetGridOptionsManual();

  useEffect(() => {
    const getAttrComps = async () => {
      const attrNames = ['MODEL', 'BRAND', 'GTIN', 'MANUFACTURER'];
      const newAttrSection = attrSections.filter((attr: any) =>
        attrNames.includes(attr.name)
      );

      if (!categoryId) {
        setAttrSections(newAttrSection);
        return;
      }

      try {
        /**
         * list of categoriesId without input/control/component created in Atibo
         * 'MLU442548', // done
         * 'MLU434708', // done
         * 'MLU442424', // done
         * 'MLU456415', // done
         * 'MLU3724', // done
         * ['MLU1744',  // text_output --> Pending (confirm apartament/house)
         * 'MLU1473', // BOOLEAN_CHECKBOX --> Pending (confirm apartament/house)
         * 'MLU1467'] // HOUSE_NUMBER_INPUT --> Pending (confirm apartament/house)
         */

        const categoryData = await loadMLCategory(categoryId);
        const resp = await loadMLCategoryGroups(categoryId);

        const domainId =
          categoryData?.settings?.catalog_domain?.split('-')?.[1];

        if (domainId) {
          getGridOptions({
            variables: {
              domainId,
            },
          });
        }

        if (Array.isArray(resp?.data?.groups) && resp?.data?.groups?.length) {
          /* const lastGroup = resp?.data?.groups?.at(-1) || {
            components: [],
          }; */ // TODO: Remove if not needed
          const lastGroups = resp.data.groups.slice(2).map((group: any) => ({
            ...group,
            components: group.components.filter((i: any) =>
              i.attributes?.some(
                (i: any) =>
                  i.tags?.includes('conditional_required') ||
                  i.tags?.includes('required') ||
                  i.id === 'GTIN'
              )
            ),
          }));

          const groups = [...resp.data.groups.slice(0, 2), ...lastGroups];

          // console.info('lastGroup....', lastGroup);

          // const groups = [
          //   ...resp.data.groups.slice(0, 2),
          //   {
          //     ...lastGroup,
          //     components: lastGroup?.components?.filter((i: any) =>
          //       i.attributes?.some(
          //         (i: any) =>
          //           i.tags?.includes('conditional_required') ||
          //           i.tags?.includes('required') ||
          //           i.id === 'GTIN'
          //       )
          //     ),
          //   },
          // ];

          const foundIds: string[] = [];
          const groupsWithoutDuplicates = groups.map((group: any) => {
            const componentsWithoutDuplicates = group.components.filter(
              (comp: any) => {
                if (foundIds.includes(comp.attributes?.[0]?.id)) {
                  return false;
                }
                foundIds.push(comp.attributes?.[0]?.id);
                return true;
              }
            );
            return {
              ...group,
              components: componentsWithoutDuplicates,
            };
          });
          const processedGroups = groupsWithoutDuplicates
            .map(processGroup)
            .filter(Boolean);
          // console.info('processedGroups...', processedGroups);
          setAttrSections(processedGroups);
        } else {
          //Empty attr section arr: No validation for attributes needed during form submission;
          setAttrSections(newAttrSection);
        }
      } catch (err) {
        console.error('>> Error - AddManualProductFormAttributes: ', err);
      }
    };
    if (!itemId) {
      getAttrComps();
    }
  }, [categoryId]);

  const createUIComponent = (
    comp: any,
    attributeValues: { name: string; value: string | number }[]
  ) => {
    // console.info('attributeValues....', attributeValues);
    // console.info('comp....', comp);
    // debugger;
    const gridId = gridOptionsData?.getGridOptions?.gridId;
    const attributeValue =
      attributeValues.find((attr) =>
        [
          comp?.label?.toLowerCase().split(' (')[0],
          comp?.attributes?.id?.toLowerCase().split(' (')[0],
        ].includes(attr?.name?.toLowerCase())
      )?.value ?? '';
    const value =
      comp.attributes.id === 'SIZE_GRID_ID' ? gridId : attributeValue;
    const attributes =
      comp.attributes.name === 'Talle'
        ? {
          ...comp.attributes,
          values: gridOptionsData?.getGridOptions?.options || [],
        }
        : comp.attributes[0] || comp.attributes; //se adapta para cargar desde el item o la carga desde amazon

    return (
      // <div>comp</div>
      <MLFormField
        key={`${comp.label}-${comp.attributes.id}`}
        controlType={comp.component}
        label={
          Array.isArray(comp.attributes) ? comp.label : comp.attributes.name
        }
        attributes={attributes}
        config={comp.ui_config}
        component={comp}
        opts={{ control }}
        value={value}
        itemId={itemId}
      />
    );
  };

  const allComponents =
    attrSections?.reduce((prev: any, curr: any) => {
      return curr.components?.length ? [...prev, ...curr.components] : prev;
    }, []) || [];

  // console.info('allcomponents...', allComponents);

  // const filledAttributes = allComponents.filter(Boolean).map((comp: any) => {
  const filledAttributes = (attrComponents.length === 0 ? allComponents : attrComponents)
    .filter(Boolean)
    .map((comp: any) => {
      const attributes = itemId ? comp.attributes[0] : comp.attributes;
      const attrName = attributes.name;
      const attrFilledByIA = selectedProductAttributesValuesData?.getSelectedProductAttributesValues?.find(
        (attr: any) => attr.name === attributes.id
      );

      const attrValue = [...(selectedProductAttributesValuesData?.getSelectedProductAttributesValues?.filter((attr: any) => attr?.value) || []), ...attributeValues]
        ?.find((attr: any) => attr?.name?.split(' (')[0]?.trim() === attrName?.split(' (')[0])?.value
        ?? attributeValues.find((attr: any) => attr.name === attributes.id)?.value
        ?? '';

      const defaultUnit = comp?.attributes?.default_unit_id;

      const numericValue = parseFloat(attrFilledByIA?.value || attrValue);

      return {
        name: comp?.label?.split(' (')[0].trim() || attributes.id,
        value: numericValue && defaultUnit ? `${numericValue} ${defaultUnit}` : numericValue ? numericValue : attrFilledByIA?.value || attrValue,
      };
    })
    .flat();
  if (gridOptionsData?.getGridOptions?.gridId) {
    filledAttributes.push({
      name: 'ID de la guía de talles',
      value: gridOptionsData?.getGridOptions?.gridId,
    });
  }

  const fillEmptyAttributes = () => {
    const selectedAttributes = allComponents
      .map((comp: any) => {
        const attr = comp.attributes;
        let hints: string[] = [];

        if (comp.default_unified_unit_id) {
          hints = [comp.default_unified_unit_id];
        } else if (comp.component === ML_FORM_TYPES.BOOLEAN_INPUT) {
          hints = ['Sí', 'No'];
        }

        if (comp.component === ML_FORM_TYPES.COMBO) {
          if (comp.ui_config?.allow_custom_value) {
            hints = comp.attributes.values
              .slice(0, 4)
              .map((val: any) => val.name);
          } else {
            hints = comp.attributes.values.map((val: any) => val.name);
          }
        }

        let attrType = '';

        if (comp.component === ML_FORM_TYPES.BOOLEAN_INPUT) {
          attrType = 'boolean';
        } else if (comp.component === ML_FORM_TYPES.COMBO && comp.ui_config) {
          attrType = comp.ui_config.allow_custom_value
            ? 'item_with_examples'
            : 'fixed_options';
        } else if (
          comp.component === ML_FORM_TYPES.NUMBER_UNIT_INPUT &&
          comp.ui_config
        ) {
          attrType = 'number_unit';
        } else if (comp.component === ML_FORM_TYPES.NUMBER_INPUT) {
          attrType = 'number';
        }

        const attrValue = getValues(attr.name) || '';

        return {
          id: attr.id,
          name: attr.name,
          value: attrValue,
          hints,
          attributeType: attrType,
        };
      })
      .filter(
        (attr: any) =>
          !['Marca', 'Fabricante', 'Código universal de producto'].includes(
            attr.name
          ) && !attr.value?.length
      );
    console.log('>> fillEmptyAttributes: ', selectedAttributes);
    if (!asin || !selectedAttributes?.length) return;

    getSelectedProductAttributesValues({
      variables: {
        categoryId,
        asin,
        selectedAttributes: {
          attributes: selectedAttributes,
        },
      },
    });
  };

  return !categoryId ||
    !Array.isArray(attrSections) ||
    attrSections.length === 0 ? (
    <></>
  ) : (
    <div className='w-full'>
      <div className='mb-4'>
        <div className='flex mt-4'>
          {!itemId && (
            <div
              className={`text-white rounded-md px-2 py-1 ${loadingAttributes ? 'text-primary-500' : 'text-primary-700'
                } bg-primary-100 border border-primary-100 hover:border-primary-500 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150 mx-2 h-8`}
              onClick={fillEmptyAttributes}
            >
              Llenar con IA
            </div>
          )}
          <div>
            {(errorLoadingAttributes &&
              JSON.stringify(errorLoadingAttributes)) ||
              ''}
          </div>
        </div>
        <div className='grid gap-4 grid-cols-3'>
          {/* {loadingAttributes
                ? 'cargando...'
                : allComponents.map((comp: any) =>
                    createUIComponent(comp, filledAttributes)
                  )} */}
          {loadingAttributes
            ? 'cargando...'
            : itemId
              ? attrComponents.map((comp: any) =>
                // createUIComponent(comp, attributeValues)
                createUIComponent(comp, filledAttributes)
              )
              : allComponents.map((comp: any) =>
                createUIComponent(comp, filledAttributes)
              )}
        </div>
      </div>
    </div>
  );
}

export { AddManualProductFormAttributes };

//TODO verificar cual es la query correcta y migrarla al backend
const loadMLCategory = memoize(
  async (categoryId: string) => {
    return await axios
      .get(`https://api.mercadolibre.com/categories/${categoryId}`)
      .then((resp) => resp.data);
  },
  (categoryId: string) => categoryId
);

const loadMLCategoryGroups = memoize(
  async (categoryId: string) => {
    return await axios.get(
      // Keeping for testing
      // 'https://api.mercadolibre.com/categories/MLU6344/technical_specs/input'
      `https://api.mercadolibre.com/categories/${categoryId}/technical_specs/input`
    );
  },
  (categoryId: string) => categoryId
);
