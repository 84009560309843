import { useEffect, useState } from 'react';
import EditIcon from './utils/iconComponents/EditIcon';
import LoadingIconComponent from './utils/iconComponents/LoadingIconComponent';
import PauseIcon from './utils/iconComponents/PauseIcon';
import PlayIcon from './utils/iconComponents/PlayIcon';
import TrashIcon from './utils/iconComponents/TrashIcon';
import { AddManualProduct } from './addProduct/manual/AddManualProduct';
import { useEditItemByIdManual } from 'hooks/useEditItemById';
import LinkArrow from './images/LinkArrow.svg';
// import { useUpdateArticleStatusManual } from 'hooks/useUpdateArticleStatus';

const ProductAllListItem = ({
  product,
  onProductSelected,
  idx,
  loadedProducts,
  setLoadedProducts,
  deleteItemById,
  onUpdateStatusHandler,
  loadingUpdate,
}) => {
  // console.info('product...', product);
  const [openModalProductForm, setOpenModalProductForm] = useState(false);
  const { updateItemById, loading } = useEditItemByIdManual();
  const [deleteItemError, setDeleteItemError] = useState();

  // const { updateArticleStatus, loading: loadingUpdate } =
  //   useUpdateArticleStatusManual();

  const editItemPayload = async (itemId, payload) => {
    // console.info('payload...', payload);

    let newPayload;
    if (!payload.hasOwnProperty('fromAttributesTab')) {
      newPayload = Object.assign(payload, {
        //asignar las variables correctas para ML
        available_quantity: payload.stock,
        seller_custom_field: payload.sku,
        currency_id: payload.currencyId,
        category_id: payload.categoryId,
      });
      newPayload.pictures = newPayload.picturesIds;
      delete payload.stock;
      delete payload.sku;
      delete payload.currencyId;
      delete payload.categoryId;
      delete newPayload.picturesIds;
    } else {
      delete payload.available_quantity;
      delete payload.category_id;
      delete payload.currency_id;
      delete payload.pictures;
      delete payload.seller_custom_field;
      delete payload.fromAttributesTab;
      newPayload = payload;
    }

    console.info('newPayload...', newPayload);

    // query update ML
    const { data } = await updateItemById({
      variables: {
        itemId,
        formData: newPayload,
      },
    });

    // data && setOpenModalProductForm(false);
  };

  // const onUpdateStatusHandler = async (status, item, idx) => {
  //   const statusResponse = await updateArticleStatus({
  //     variables: {
  //       item,
  //       status,
  //     },
  //   });

  //   if (statusResponse?.data?.updateArticleStatus?.status) {
  //     loadedProducts[idx].status =
  //       statusResponse?.data?.updateArticleStatus?.status;
  //     setLoadedProducts([...loadedProducts]);
  //   }
  // };

  const onSendCorrectionToMlHandler = async (itemId, payload, idx) => {
    console.info('payload to correction...', payload);
    //usamos esta funcion mientras se envia correccion a ML
    const newPayload = {
      available_quantity: payload.stock,
      currency_id: payload.currencyId,
      price: payload.price,
    };

    const { data } = await updateItemById({
      variables: {
        itemId,
        formData: newPayload,
      },
    });

    if (data) {
      console.info('data..', data);
      setOpenModalProductForm(false);
      if (data?.updateItemById?.payload) {
        loadedProducts[idx].available_quantity =
          data?.updateItemById?.payload?.available_quantity;
        loadedProducts[idx].price = data?.updateItemById?.payload?.price;
        loadedProducts[idx].currency_id =
          data?.updateItemById?.payload?.currency_id;

        setLoadedProducts([...loadedProducts]);
      }
    }
  };

  return (
    <>
      <div
        key={idx}
        className='w-full h-[72px] px-4 py-2 border-b hover:bg-gray-50 border-gray-200 justify-start items-center gap-3 inline-flex'
      >
        <div className='w-[8%]'>
          {product.catalog_listing ? (
            <div className='max-w-[75px] relative'>
              <img
                className='w-[75px] h-14 rounded-[10px]'
                src={product.image}
                alt={product.image ? product.title : ''}
              />
              <div
                style={{
                  position: 'absolute',
                  inset: '4px calc(-1 * 10px) auto auto',
                  padding: '0 10px 10px calc(10px + 4px)',
                  clipPath:
                    'polygon(0 0,100% 0,100% calc(100% - 10px), calc(100% - 10px) 100%, calc(100% - 10px) calc(100% - 10px), 0 calc(100% - 10px), 5px calc(50% - 10px/2))',
                  background: '#BD1550',
                  boxShadow: '0 calc(-1 * 10px) 0 inset #0005',
                  color: 'white',
                  fontSize: '7px',
                }}
              >
                Catalogo
              </div>
            </div>
          ) : (
            <img
              className='w-[75px] h-14 rounded-[10px]'
              src={product.image}
              alt={product.image ? product.title : ''}
            />
          )}
        </div>
        <div className='w-[30%]'>
          <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
            {product.title}
          </div>
        </div>
        <div className='w-[15%] text-xs flex justify-center text-gray-900'>
          <a
            href={product.permalink}
            target='_blank'
            rel='noreferrer'
            className='text-primary-700 flex items-center'
          >
            <span>{product.id}</span>
            <img src={LinkArrow} alt='Arrow' className='ml-1 w-2 h-2' />
          </a>
        </div>
        <div className='w-[15%] text-xs flex justify-center text-gray-900'>
          {product?.seller_custom_field && (
            <a
              href={`https://amazon.com/dp/${product.seller_custom_field}`}
              target='_blank'
              rel='noreferrer'
              className='text-primary-700 flex items-center'
            >
              <span>{product.seller_custom_field}</span>
              <img src={LinkArrow} alt='Arrow' className='ml-1 w-2 h-2' />
            </a>
          )}
        </div>
        <div className='w-[15%] text-xs flex justify-center text-gray-900'>
          {product?.catalog_listing && (
            <a
              href={`https://mercadolibre.com.uy/p/${product.catalog_product_id}`}
              target='_blank'
              rel='noreferrer'
              className='text-primary-700 flex items-center'
            >
              <span>{product.catalog_product_id}</span>
              <img src={LinkArrow} alt='Arrow' className='ml-1 w-2 h-2' />
            </a>
          )}
        </div>
        <div className='w-[12%] flex justify-center'>
          <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
            {product.currency_id} {product.price}
          </div>
        </div>
        <div className='w-[10%] flex justify-center'>
          <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
            {product.available_quantity}
          </div>
        </div>
        <div className='w-[4%] flex justify-center'>
          <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
            {product.sold_quantity}
          </div>
        </div>
        <div className='w-[12%] flex justify-center'>
          <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
            {product.status}
          </div>
        </div>
        <div className='w-[10%]'>
          {loadingUpdate ? (
            <span className='flex justify-center'>
              <LoadingIconComponent />
            </span>
          ) : (
            <div
              className={`pl-4 pr-1 py-0.5 rounded-2xl justify-left items-center gap-1 flex`}
              onClick={() => onProductSelected(product)}
              aria-hidden={true}
            >
              {product.status == 'Pausado' && (
                <div
                  onClick={() =>
                    onUpdateStatusHandler('active', product.id, idx)
                  }
                  aria-hidden={true}
                >
                  <PlayIcon title='Activar artículo' />
                </div>
              )}
              {product.status == 'Activo' && (
                <div
                  onClick={() =>
                    onUpdateStatusHandler('paused', product.id, idx)
                  }
                  aria-hidden={true}
                >
                  <PauseIcon title='Pausar artículo' />
                </div>
              )}
              <div
                onClick={() => setOpenModalProductForm(true)}
                aria-hidden={true}
              >
                <EditIcon title='Editar artículo' />
              </div>
              {(product.status == 'Pausado' || product.status == 'Cerrado') && (
                <div
                  onClick={() => deleteItemById(product.id, idx)}
                  aria-hidden={true}
                >
                  <TrashIcon title='Eliminar artículo' />
                </div>
              )}
              <div className='w-3 h-3 relative'></div>
            </div>
          )}
        </div>
      </div>
      <AddManualProduct
        visible={openModalProductForm}
        identifier={product.seller_custom_field}
        closeButtonLabel={() => null}
        onCancel={() => setOpenModalProductForm(false)}
        onPublish={() => null}
        titleAsinProduct={product.title || ''}
        price={product.price || 0}
        currency={product.currency_id || ''}
        productDescriptionAsin={product.descriptions || ''}
        productImages={product.pictures || []}
        productName={product.title || ''}
        categoryPath={product.categoryPath || []}
        attributeValues={product?.attributes || []}
        formTitle='Editar producto'
        buttonName={'Editar'}
        onSubmit={(payload) =>
          !product.catalog_listing
            ? editItemPayload(product.id, payload)
            : onSendCorrectionToMlHandler(product.id, payload, idx)
        }
        loadingFetch={loading}
        soldQuantity={product.sold_quantity}
        itemId={product.id}
        stock={product.available_quantity}
        catalog_listing={product.catalog_listing}
        permalink={product?.permalink || ''}
        catalog_product_id={product?.catalog_product_id || ''}
      />
    </>
  );
};

export default ProductAllListItem;
