import { useEffect, useState } from 'react';
import { useBottomReached } from '../../hooks/useBottomReached';
import { SearchBox } from '../SearchBox';
import { useSearchMlCatalogProductsManual } from '../../hooks/useSearchMlCatalogProducts';
import { AddCatalogProduct } from 'components/addProduct/catalog/AddCatalogProduct';
import { AddManualProduct } from '../addProduct/manual/AddManualProduct';
import { useSearchAsin } from '../../hooks/useSearchAsin';
import { useSearchProductInfoByIdentifier } from 'hooks/useSearchProductInfoByIdentifier';
import { AddManualProductCopilot } from '../addProduct/manual/AddManualProductCopilot';
import { useGetCatalogProductByAsinManual } from 'hooks/useGetCatalogProductByAsin';
import { useGetCatalogProductByUPCManual } from 'hooks/useGetCatalogProductByUPC';
import { useGetMlItemByAsinManual } from 'hooks/useGetMlItemByAsin';
import { useGetMlItemsByAsinManual } from 'hooks/useGetMlItemsByAsin';
import { useGetMlItemsByUpcManual } from 'hooks/useGetMlItemsByUPC';
import { useGetMlItemByUpcManual } from 'hooks/useGetMlItemByUPC';
import { useGetAmazonProductByAsinManual } from 'hooks/useGetAmazonProductByAsin';
import { useGetMlItemByIdManual } from 'hooks/useGetMlItemById';
import { ExclamationIcon } from './ExclamationIcon';
import { SpinnerIcon } from './SpinnerIcon';
import { ActionButton } from './ActionButton';
import { MlItemsTabs } from './MlItemsTabs';
import { useAddAmazonGtinsToMlItem } from 'hooks/useAddAmazonGtinsToMlItem';
import { ProductListItem } from './ProductListItem';
import { CompareElegibleProductModal } from '../CompareElegibleProductModal';
import * as _ from 'lodash';
import ArticleIcon from 'components/utils/iconComponents/ArticleIcon';

const ADDING_PRODUCT_METHODS = {
  COPILOT: 'COPILOT',
  MANUAL: 'MANUAL',
  OPTIN: 'OPTIN',
  CATALOG: 'CATALOG',
};

export const MlCatalogProductsList = ({
  showManualPublicationButton,
  selectedTabNumber,
  setSelectedTabNumber,
}) => {
  const {
    getCatalogProductByAsin,
    loading: loadingCatalogProductByAsin,
    data: catalogProductByAsin,
  } = useGetCatalogProductByAsinManual();
  const {
    getCatalogProductByUPC,
    loading: loadingCatalogProductByUPC,
    data: catalogProductByUPC,
  } = useGetCatalogProductByUPCManual();
  const {
    getMlItemByAsin,
    loading: loadingMlItemByAsin,
    data: mlItemByAsin,
  } = useGetMlItemByAsinManual();
  const {
    getMlItemsByAsin,
    loading: loadingMlItemsByAsin,
    data: mlItemsByAsin,
  } = useGetMlItemsByAsinManual();
  const {
    getMlItemsByUpc,
    loading: loadingMlItemsByUpc,
    data: mlItemsByUpc,
  } = useGetMlItemsByUpcManual();
  const {
    getMlItemByUpc,
    loading: loadingMlItemByUpc,
    data: mlItemByUpc,
  } = useGetMlItemByUpcManual();
  const {
    getAmazonProductByAsin,
    loading: loadingAmazonProductByAsin,
    data: amazonProductByAsin,
  } = useGetAmazonProductByAsinManual();
  const [query, setQuery] = useState('');
  const [publishProductModalVisible, setPublishProductModalVisible] =
    useState(false);
  const { searchMlCatalogProducts, loading, error, products, total } =
    useSearchMlCatalogProductsManual();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [catalogProducts, setCatalogProducts] = useState(products);
  const [page, setPage] = useState(0);
  const bottomOfScreenReached = useBottomReached();
  const [loadedProducts, setLoadedProducts] = useState([]);
  const [errorInfo, setErrorInfo] = useState({
    message: '',
    statusText: '',
  });
  const pageSize = 20;
  const lastPage = Math.floor(total / pageSize);
  const [addingProductMethod, setAddingProductMethod] = useState(null);

  const [mlItem, setMlItem] = useState(null);
  const [mlItems, setMlItems] = useState([]);
  const [catalogProduct, setCatalogProduct] = useState(null);
  const [showAPublishDialog, setShowAPublishDialog] = useState(false);
  const [showConvertToCatalogDialog, setShowConvertToCatalogDialog] =
    useState(false);

  //asin info
  const [asinPrice, setAsinPrice] = useState(0);
  const [itemProductInfoAsin, setItemProductInfoAsin] = useState();
  const [amazonProduct, setAmazonProduct] = useState(null);
  const addAmazonGtinsToMlItem = useAddAmazonGtinsToMlItem();

  const isUPC = (query) => /^\d{11,14}$/.test(query);
  const isASIN = (query) => /^B[0-9,A-Z]{9}$/.test(query);

  const {
    getMlItemById,
    data: mlItemById,
    loading: loadingMlItemById,
  } = useGetMlItemByIdManual();

  //consulta por asin
  const { loading: loadingAsinQuery } = useSearchAsin();

  //consulta por upc, ean
  const { inCatalog: catalogProductInfo, loading: loadingProductInfo } =
    useSearchProductInfoByIdentifier();

  const [reachedTable, setReachedTable] = useState(false);

  useEffect(() => {
    if (loadingCatalogProductByAsin) {
      setCatalogProduct(null);
    } else {
      setCatalogProduct(catalogProductByAsin?.getCatalogProductByAsin);
    }
  }, [loadingCatalogProductByAsin]);

  useEffect(() => {
    if (loadingCatalogProductByUPC) {
      setCatalogProduct(null);
    } else {
      setCatalogProduct(catalogProductByUPC?.getCatalogProductByUpc);
    }
  }, [loadingCatalogProductByUPC]);

  useEffect(() => {
    if (!loadingAmazonProductByAsin) {
      setAmazonProduct({
        ...(amazonProductByAsin?.getAmazonProductByAsin || {}),
        pictures:
          amazonProductByAsin?.getAmazonProductByAsin?.images?.map((src) => ({
            src,
          })) || [],
      });
    }
  }, [loadingAmazonProductByAsin]);

  const formatItemsTree = (items) => {
    const groupsDict = items.reduce((acc, item) => {
      const existingGroup =
        acc[item?.id] ||
        acc[
        item?.item_relations
          ?.map((relation) => relation.id)
          .toSorted((a, b) => a.localeCompare(b))[0]
        ];
      if (!existingGroup) {
        const ids = _.uniq([
          item?.id,
          ...(item?.item_relations?.map((relation) => relation.id) || []),
        ]).filter((id) => id);
        const parentId = ids.toSorted((a, b) => a.localeCompare(b))[0];
        const children = ids.filter((id) => id !== parentId);
        acc[parentId] = { id: parentId, children };
      }
      return acc;
    }, {});
    const keys = window.Object.keys(groupsDict);
    if (!keys) return;
    const groups = keys?.map((key) => ({
      id: key,
      children: groupsDict[key]?.children,
    })); // TODO: We are using window.Object to avoid the conflict with an obscure bug of the compiler
    const groupsSortedBySize = groups.toSorted(
      (a, b) => b.children.length - a.children.length
    );
    const flattenedList = groupsSortedBySize.reduce((acc, group) => {
      const parent = items.find((item) => item.id === group.id);
      const children = group.children
        .map((id) => items.find((item) => item.id === id))
        .map((item) => ({ ...item, isChild: true }));
      acc.push(parent, ...children);
      return acc;
    }, []);
    return flattenedList;
  };

  useEffect(() => {
    if (!loadingMlItemsByAsin) {
      const items = mlItemsByAsin?.getMlItemsByAsin?.filter((item) => item?.id) || [];
      if (items.length > 0) {
        setMlItems(formatItemsTree(items));
      } else {
        setMlItems([]);
      }
    }
  }, [loadingMlItemsByAsin]);

  useEffect(() => {
    if (!loadingMlItemsByUpc) {
      const items = mlItemsByUpc?.getMlItemsByUpc?.filter((item) => item?.id) || [];
      if (items.length > 0) {
        setMlItems(formatItemsTree(items));
      } else {
        setMlItems([]);
      }
    }
  }, [loadingMlItemsByUpc]);

  useEffect(() => {
    setPage(0);
    setLoadedProducts([]);
    setItemProductInfoAsin('');
    setMlItems([]);
    setCatalogProduct(null);
    setShowAPublishDialog(false);
    setAmazonProduct(null);

    const queryIsAsin = /^B[0-9,A-Z]{9}$/.test(query);
    const queryIsUpc = /^\d{11,14}$/.test(query);

    const searchByAsin = () => {
      getCatalogProductByAsin({
        variables: { asin: query },
      });
      getMlItemsByAsin({
        variables: { asin: query },
      });
      setMlItems([]);
    };

    const searchByUpc = () => {
      getCatalogProductByUPC({
        variables: { upc: query },
      });
      getMlItemsByUpc({
        variables: { upc: query },
      });
      setMlItems([]);
    };

    const searchByQuery = () => {
      searchMlCatalogProducts({
        variables: { query, offset: page * pageSize, limit: pageSize },
      });
    };

    if (queryIsAsin || queryIsUpc) {
      getAmazonProductByAsin({ variables: { asin: query } });
    }

    if (queryIsAsin) {
      searchByAsin();
    } else if (queryIsUpc) {
      searchByUpc();
    } else {
      searchByQuery();
    }
  }, [query]);

  useEffect(() => {
    const queryIsAsin = /^B[0-9,A-Z]{9}$/.test(query);
    if (!loadingCatalogProductByAsin && !loadingMlItemByAsin && queryIsAsin) {
      const catalogProduct = catalogProductByAsin?.getCatalogProductByAsin;
      const mlItem = mlItemByAsin?.getMlItemByAsin;

      if (mlItem) {
        setAsinPrice(mlItem.price);
        setItemProductInfoAsin(mlItemByAsin?.mlItemByAsin);
      }

      if (!mlItem && !catalogProduct && catalogProductByAsin && mlItemByAsin) {
        setAddingProductMethod(ADDING_PRODUCT_METHODS.COPILOT);
      } else if (!mlItem && catalogProduct) {
        setAddingProductMethod(ADDING_PRODUCT_METHODS.CATALOG);
      } else if (mlItem && catalogProduct && !mlItem.catalog_listing) {
        setAddingProductMethod(ADDING_PRODUCT_METHODS.OPTIN);
      } else {
        setAddingProductMethod(null);
      }
    }
  }, [loadingCatalogProductByAsin, loadingMlItemByAsin]);

  useEffect(() => {
    const queryIsUpc = /^\d{11,14}$/.test(query);
    if (!loadingCatalogProductByUPC && !loadingMlItemByUpc && queryIsUpc) {
      const catalogProduct = catalogProductByUPC?.getCatalogProductByUpc;
      const mlItem = mlItemByUpc?.getMlItemByUpc;

      if (mlItem) {
        setAsinPrice(mlItem.price);
        setItemProductInfoAsin(mlItemByUpc?.mlItemByUpc);
      }

      if (!mlItem && !catalogProduct && catalogProductByUPC && mlItemByUpc) {
        setAddingProductMethod(ADDING_PRODUCT_METHODS.COPILOT);
      } else if (!mlItem && catalogProduct) {
        setAddingProductMethod(ADDING_PRODUCT_METHODS.CATALOG);
      } else if (mlItem && catalogProduct && !mlItem.catalog_listing) {
        setAddingProductMethod(ADDING_PRODUCT_METHODS.OPTIN);
      } else {
        setAddingProductMethod(null);
      }
    }
  }, [loadingCatalogProductByUPC, loadingMlItemByUpc]);

  useEffect(() => {
    const queryIsUpc = isUPC(query);
    const queryIsAsin = isASIN(query);
    if (!queryIsAsin && !queryIsUpc) {
      searchMlCatalogProducts({
        variables: { query, offset: page * pageSize, limit: pageSize },
      });
    }
  }, [page]);

  useEffect(() => {
    if (!products.length && /^MLU[0-9]{4,9}$/.test(query)) {
      getMlItemById({ variables: { id: query } });
    }
  }, [products]);

  useEffect(() => {
    if (mlItemById) {
      setMlItems([mlItemById?.getMlItemById]);
    }
  }, [mlItemById]);

  useEffect(() => {
    setCatalogProducts(products);
  }, [products]);

  useEffect(() => {
    if ((bottomOfScreenReached || reachedTable) && products && !loading && page < lastPage) {
      setReachedTable(false);
      setPage(page + 1);
    }
  }, [bottomOfScreenReached, reachedTable]);

  useEffect(() => {
    if (catalogProducts) {
      setLoadedProducts(catalogProducts);
    }
  }, [catalogProducts]);

  const handlePublishProduct = async (bid) => {
    console.log(bid);
    setPublishProductModalVisible(false);
  };

  useEffect(() => {
    if (error) {
      const dataError = JSON.parse(error.httpError.body);
      setErrorInfo({
        message: dataError.errors[0].message,
        statusText: error.httpError.statusText,
      });
    }
  }, [error]);

  useEffect(() => {
    if (!loadingProductInfo) {
      if (catalogProductInfo) {
        setSelectedProduct(null);
        setPublishProductModalVisible(false);
      }
    }
  }, [loadingProductInfo]);

  useEffect(() => {
    if (loadedProducts?.length == 0 && itemProductInfoAsin?.id) {
      const dataCatalog = [
        {
          id: itemProductInfoAsin.id,
          domain_id: '',
          name: itemProductInfoAsin.title,
          parent_id: '',
          permalink: itemProductInfoAsin.permalink,
          pickers: [],
          pictures: [],
          thumbnail: itemProductInfoAsin.thumbnail,
        },
      ];
      setLoadedProducts(dataCatalog);
    }
  }, [itemProductInfoAsin]);

  const handlerPostClick = (catalogProduct) => {
    if (catalogProduct) {
      setAddingProductMethod(ADDING_PRODUCT_METHODS.CATALOG);
    } else {
      setAddingProductMethod(ADDING_PRODUCT_METHODS.COPILOT);
    }
  };

  const publishWithCopilot = () => {
    setAddingProductMethod(ADDING_PRODUCT_METHODS.COPILOT);
    setShowAPublishDialog(true);
  };

  const publishCatalogProduct = (catalogProduct) => {
    if (catalogProduct) {
      setCatalogProduct(catalogProduct);
      setAddingProductMethod(ADDING_PRODUCT_METHODS.CATALOG);
      setPublishProductModalVisible(true);
    } else {
      setCatalogProduct(null);
      setAddingProductMethod(ADDING_PRODUCT_METHODS.COPILOT);
      setShowAPublishDialog(true);
    }
  };

  const showManualProductCopilot =
    addingProductMethod == ADDING_PRODUCT_METHODS.COPILOT &&
    !loadingCatalogProductByAsin &&
    !loadingCatalogProductByUPC &&
    showAPublishDialog;
  const showManualProduct =
    !showManualProductCopilot &&
    addingProductMethod == ADDING_PRODUCT_METHODS.MANUAL &&
    showAPublishDialog;
  const addingCatalogProductFromScratch =
    addingProductMethod == ADDING_PRODUCT_METHODS.CATALOG &&
    catalogProduct &&
    showAPublishDialog;
  const showSpinner =
    loading ||
    loadingAsinQuery ||
    loadingCatalogProductByAsin ||
    loadingCatalogProductByUPC ||
    loadingMlItemsByAsin ||
    loadingMlItemByUpc ||
    loadingAmazonProductByAsin ||
    loadingMlItemsByUpc;
  const singleProductCount =
    mlItems?.length || catalogProduct || amazonProduct ? 1 : 0;
  const productsFoundCount = loadedProducts
    ? loadedProducts.length
    : singleProductCount;

  const handlerScroll = (e) => {
    const target = e.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setReachedTable(true);
    }
  }

  return (
    <>
      {showManualProductCopilot && (
        <AddManualProductCopilot
          onCancel={() => setShowAPublishDialog(false)}
          onPublish={() => setShowAPublishDialog(false)}
          asin={amazonProduct?.asin}
          closeButtonLabel='Cerrar'
        />
      )}
      {showManualProduct && (
        <AddManualProduct
          onCancel={() => setShowAPublishDialog(false)}
          onPublish={() => setAddingProductMethod(null)}
          closeButtonLabel='Cerrar'
          visible={true}
        />
      )}
      {publishProductModalVisible && (
        <AddCatalogProduct
          product={catalogProduct}
          visible={publishProductModalVisible}
          onPublish={handlePublishProduct}
          onCancel={() => setPublishProductModalVisible(false)}
          asinPrice={asinPrice}
          closeButtonLabel='Cerrar'
          asin={amazonProduct?.asin}
        />
      )}
      {addingCatalogProductFromScratch && (
        <AddCatalogProduct
          product={catalogProduct}
          visible={true}
          onPublish={handlePublishProduct}
          onCancel={() => setPublishProductModalVisible(false)}
          asinPrice={0}
          closeButtonLabel='Cerrar'
          asin={amazonProduct?.asin}
        />
      )}
      {/* <div className='self-stretch h-[60px] justify-start items-center gap-6 inline-flex'> */}
      <div className='w-full h-[60px] flex justify-between mb-2'>
        <div className='w-[3%] h-[60px] flex justify-start p-2 items-center'>
          <ArticleIcon />
        </div>
        <div className='w-[57%] h-[60px] flex justify-start p-[10px]'>
          <MlItemsTabs
            selectedTab={selectedTabNumber}
            onTabClick={(tabNumber) => setSelectedTabNumber(tabNumber)}
          />
        </div>
        <div className='w-[40%] h-[60px] flex justify-end'>
          <div className='flex items-center'>
            <ActionButton
              onClick={() => {
                setAddingProductMethod(ADDING_PRODUCT_METHODS.MANUAL);
                setShowAPublishDialog(true);
              }}
              message='Agregar articulo'
            />
            <div className='w-80 h-11 flex-col justify-start items-start gap-1.5 flex'>
              <div className='h-[44px] self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2'>
                <div className='grow shrink basis-0 h-6 justify-start items-center gap-2 flex'>
                  <div className="text-gray-500 text-base font-normal font-['Inter'] leading-normal"></div>
                  <SearchBox query={query} onQueryChanged={setQuery} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full self-stretch h-11 px-6 py-3 bg-gray-50 shadow border border-b-0 border-gray-200 justify-start items-center gap-3 inline-flex rounded-t-xl'>
        <div className='w-full justify-start items-center gap-1 flex'>
          <div className="w-[8%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px] text-gray-600">
            Imagen
          </div>
          <div className="w-[75%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px] text-gray-600">
            Titulo
          </div>
          <div className="w-[17%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]"></div>
        </div>
      </div>
      <div
        className='w-full flex-col justify-start items-start gap-6 inline-flex'
        style={{ height: 'calc(100vh - 130px)' }}
      >
        <div className='self-stretch h-[833px] bg-white flex-col justify-start items-start flex rounded-t-0 rounded-b-xl'>
          <div className='w-full h-full bg-white rounded-t-0 rounded-b-xl shadow border border-gray-200 flex-col justify-start items-start flex overflow-y-auto' onScroll={handlerScroll}>
            <div className='self-stretch bg-white justify-start items-start inline-flex'>
              <div
                className='w-full flex-col justify-start items-start inline-flex'
                style={{ height: 'calc(100vh - 130px)' }}
              >
                {
                  // Normal query list
                  loadedProducts
                    ?.filter((p) => p?.id)
                    .map((catalogProduct) => (
                      <ProductListItem
                        key={catalogProduct.id}
                        loading={loadingAsinQuery || loadingProductInfo}
                        catalogProduct={catalogProduct}
                        href={`https://mercadolibre.com.uy/p/${catalogProduct.id}`}
                        onPublishCatalogProduct={publishCatalogProduct}
                      />
                    ))
                }
                {
                  // Amazon Product
                  (isUPC(query) || isASIN(query)) &&
                  !showSpinner &&
                  !mlItems?.length &&
                  !catalogProduct &&
                  amazonProduct?.asin && (
                    <ProductListItem
                      key={amazonProduct?.asin}
                      loading={showSpinner}
                      mlItem={mlItem}
                      href={`https://www.amazon.com/dp/${amazonProduct?.asin}`}
                      catalogProduct={catalogProduct}
                      amazonProduct={amazonProduct}
                      onPublishFromAmazon={publishWithCopilot}
                      onQuickOptin={(mlItem, asin) =>
                        console.log('optin:', mlItem, catalogProduct)
                      }
                    />
                  )
                }
                {
                  // ML Item
                  !showSpinner &&
                  mlItems
                    ?.filter((i) => i?.id)
                    .map((mlItem) => (
                      <>
                        <ProductListItem
                          key={mlItem?.id}
                          loading={showSpinner}
                          mlItem={mlItem}
                          catalogProduct={catalogProduct}
                          amazonProduct={amazonProduct}
                          href={`https://articulo.mercadolibre.com.uy/MLU-${mlItem.id.slice(
                            3
                          )}`}
                          onQuickOptin={async (mlItem, catalogProduct) => {
                            const result = await addAmazonGtinsToMlItem({
                              variables: {
                                asin: amazonProduct?.asin,
                                mlItemId: mlItem?.id,
                              },
                            });
                            console.log(result);
                          }}
                          onConvertToCatalog={(mlItem, asin) =>
                            setShowConvertToCatalogDialog(true)
                          }
                        />
                        {showConvertToCatalogDialog && (
                          <CompareElegibleProductModal
                            product={{
                              id: mlItem.id,
                              sellerCustomField: mlItem.seller_custom_field,
                              catalogProduct,
                              details: {
                                title: mlItem.title,
                                thumbnail: mlItem.thumbnail,
                                pictures: mlItem.pictures,
                                permalink: `https://articulo.mercadolibre.com.uy/MLU-${mlItem.id.slice(
                                  3
                                )}`,
                                catalog_product_id: catalogProduct?.id,
                              },
                            }}
                            onClose={() =>
                              setShowConvertToCatalogDialog(false)
                            }
                          />
                        )}
                      </>
                    ))
                }

                {
                  // Catalog Product
                  !showSpinner && !mlItems.length && catalogProduct && (
                    <ProductListItem
                      key={catalogProduct?.id}
                      loading={showSpinner}
                      mlItem={mlItem}
                      catalogProduct={catalogProduct}
                      amazonProduct={amazonProduct}
                      href={`https://mercadolibre.com.uy/p/${catalogProduct.id}`}
                      onPublishCatalogProduct={publishCatalogProduct}
                    />
                  )
                }
                {showSpinner && (
                  <div className='ml-4 mt-4'>
                    <SpinnerIcon />
                  </div>
                )}
                {error && (
                  <div className='ml-4 mt-4'>
                    <ExclamationIcon
                      message={errorInfo.statusText + ' ' + errorInfo.message}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
