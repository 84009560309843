import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import type { ECharts, EChartsOption } from 'echarts';
import { useGetStoreSalesStatsManual } from 'hooks/useGetStoreSalesStats';
import { PeriodSelector } from '../PeriodSelector/PeriodSelector';

export const SalesEvolution: React.FC = () => {
	const chartRef = useRef(null);
	const [chart, setChart] = useState<ECharts | null>(null);
	const { getStoreSalesStats, loading, error, data } = useGetStoreSalesStatsManual();
	const [currentView, setCurrentView] = useState<string>('year');
	const [chartTitle, setChartTitle] = useState<string>('Evolución de las ventas (por año)');

	useEffect(() => {
		const fetchData = async () => {
			await getStoreSalesStats({ variables: { period: currentView } });
		};
		fetchData();
	}, [getStoreSalesStats, currentView]);

	useEffect(() => {
		const initChart = () => {
			if (chartRef.current) {
				const newChart = echarts.init(chartRef.current);
				setChart(newChart);

				const loadingOption: EChartsOption = {
					text: 'Cargando datos...',
					color: '#9E77ED',
					textColor: '#000',
					maskColor: 'rgba(255, 255, 255, 0.8)',
					zlevel: 0
				};

				if (loading) {
					newChart.showLoading(loadingOption as any);
				} else if (data) {
					newChart.hideLoading();

					const salesData = data.getStoreSalesStats
						.sort((a: any, b: any) => parseInt(a.time) - parseInt(b.time));

					const formatDate = (timestamp: string) => {
						const dateUTC = new Date(parseInt(timestamp));
						const userTimezoneOffset = dateUTC.getTimezoneOffset() * 60000;
						const dateLocal = new Date(dateUTC.getTime() - userTimezoneOffset);
						return dateLocal;
					};

					let xAxisData: string[];
					let confirmedCatalogSales: number[];
					let confirmedMarketplaceSales: number[];
					let unconfirmedCatalogSales: number[];
					let unconfirmedMarketplaceSales: number[];

					if (currentView === 'year') {
						const monthYears = salesData.map((item: any) => {
							const date = formatDate(item.time);
							return `${date.toLocaleString('default', { month: 'short' })}\n${date.getFullYear()}`;
						});

						xAxisData = Array.from(new Set(monthYears));

						confirmedCatalogSales = xAxisData.map(monthYear =>
							salesData.filter((item: any) => {
								const date = formatDate(item.time);
								return `${date.toLocaleString('default', { month: 'short' })}\n${date.getFullYear()}` === monthYear && item.catalogListing && item.confirmed;
							}).reduce((sum: number, item: any) => sum + item.totalQuantity, 0)
						);

						confirmedMarketplaceSales = xAxisData.map(monthYear =>
							salesData.filter((item: any) => {
								const date = formatDate(item.time);
								return `${date.toLocaleString('default', { month: 'short' })}\n${date.getFullYear()}` === monthYear && !item.catalogListing && item.confirmed;
							}).reduce((sum: number, item: any) => sum + item.totalQuantity, 0)
						);

						unconfirmedCatalogSales = xAxisData.map(monthYear =>
							salesData.filter((item: any) => {
								const date = formatDate(item.time);
								return `${date.toLocaleString('default', { month: 'short' })}\n${date.getFullYear()}` === monthYear && item.catalogListing && !item.confirmed;
							}).reduce((sum: number, item: any) => sum + item.totalQuantity, 0)
						);

						unconfirmedMarketplaceSales = xAxisData.map(monthYear =>
							salesData.filter((item: any) => {
								const date = formatDate(item.time);
								return `${date.toLocaleString('default', { month: 'short' })}\n${date.getFullYear()}` === monthYear && !item.catalogListing && !item.confirmed;
							}).reduce((sum: number, item: any) => sum + item.totalQuantity, 0)
						);
					} else {
						const dailyData: { [key: string]: { confirmedCatalog: number, confirmedMarketplace: number, unconfirmedCatalog: number, unconfirmedMarketplace: number } } = {};

						let startDate: Date, endDate: Date;
						if (currentView === 'month') {
							endDate = new Date();
							startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate() + 1);
						} else {
							endDate = new Date();
							startDate = new Date(endDate.getTime() - 6 * 24 * 60 * 60 * 1000);
						}

						for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
							const key = d.toISOString().split('T')[0];
							dailyData[key] = { confirmedCatalog: 0, confirmedMarketplace: 0, unconfirmedCatalog: 0, unconfirmedMarketplace: 0 };
						}

						salesData.forEach((item: any) => {
							const date = formatDate(item.time);
							const key = date.toISOString().split('T')[0];
							if (dailyData[key]) {
								if (item.catalogListing) {
									if (item.confirmed) {
										dailyData[key].confirmedCatalog += item.totalQuantity;
									} else {
										dailyData[key].unconfirmedCatalog += item.totalQuantity;
									}
								} else {
									if (item.confirmed) {
										dailyData[key].confirmedMarketplace += item.totalQuantity;
									} else {
										dailyData[key].unconfirmedMarketplace += item.totalQuantity;
									}
								}
							}
						});

						xAxisData = Object.keys(dailyData).sort();
						confirmedCatalogSales = xAxisData.map(date => dailyData[date].confirmedCatalog);
						confirmedMarketplaceSales = xAxisData.map(date => dailyData[date].confirmedMarketplace);
						unconfirmedCatalogSales = xAxisData.map(date => dailyData[date].unconfirmedCatalog);
						unconfirmedMarketplaceSales = xAxisData.map(date => dailyData[date].unconfirmedMarketplace);
					}

					const option: EChartsOption = {
						title: {
							text: chartTitle,
							left: 'left'
						},
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'shadow'
							}
						},
						legend: {
							data: ['Confirmadas de catálogo', 'Confirmadas de marketplace', 'No confirmadas de catálogo', 'No confirmadas de marketplace'],
							bottom: '0%',
							left: 'center',
							padding: [10, 0, 0, 0]
						},
						grid: {
							left: '3%',
							right: '4%',
							bottom: '15%',
							containLabel: true
						},
						xAxis: [
							{
								type: 'category',
								data: xAxisData,
								axisLabel: {
									interval: 0,
									formatter: (value: string) => {
										if (currentView === 'year') {
											const [month, year] = value.split('\n');
											return `{a|${month}}\n{b|${year}}`;
										} else {
											const date = new Date(value);
											return `{a|${date.getDate()}}\n{b|${date.toLocaleString('default', { month: 'short' })}}`;
										}
									},
									rich: {
										a: {
											fontWeight: 'bold',
											lineHeight: 20
										},
										b: {
											fontSize: 10,
											lineHeight: 20
										}
									}
								}
							}
						],
						yAxis: [
							{
								type: 'value',
								name: 'Número de productos',
								nameLocation: 'middle',
								nameGap: 50
							}
						],
						series: [
							{
								name: 'Confirmadas de catálogo',
									type: 'bar',
									stack: 'total',
									itemStyle: { color: '#9E77ED' },
									emphasis: { focus: 'series' },
								data: confirmedCatalogSales
							},
							{
								name: 'Confirmadas de marketplace',
								type: 'bar',
								stack: 'total',
								itemStyle: { color: '#C6B9E8' },
								emphasis: { focus: 'series' },
								data: confirmedMarketplaceSales
							},
							{
								name: 'No confirmadas de catálogo',
								type: 'bar',
								stack: 'total',
								itemStyle: { color: '#FFD700' }, // Amarillo oscuro
								emphasis: { focus: 'series' },
								data: unconfirmedCatalogSales
							},
							{
								name: 'No confirmadas de marketplace',
								type: 'bar',
								stack: 'total',
								itemStyle: { color: '#E6D388' },
								emphasis: { focus: 'series' },
								data: unconfirmedMarketplaceSales
							}
						]
					};

					newChart.setOption(option as any);
				}
			}
		};

		initChart();

		const handleResize = () => {
			chart?.resize();
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			chart?.dispose();
		};
	}, [data, loading, chart, currentView, chartTitle]);

	useEffect(() => {
		if (chart) {
			chart.setOption({
				title: {
					text: chartTitle
				}
			});
		}
	}, [chart, chartTitle]);

	const updateView = (view: string) => {
		setCurrentView(view);
		setChartTitle(`Evolución de las ventas (${view === 'year' ? 'último año' : view === 'month' ? 'último mes' : 'última semana'})`);
	};

	if (error) return <div>Error al cargar los datos</div>;

	return (
		<div style={{ position: 'relative', width: '100%', height: '100%' }}>
			<div style={{
				position: 'absolute',
				top: '10px',
				right: '60px',
				zIndex: 1
			}}>
				<PeriodSelector currentView={currentView} onViewChange={updateView} />
			</div>
			<div
				ref={chartRef}
				style={{
					width: '100%',
					height: '100%',
					minHeight: '400px',
					maxWidth: '100%',
					overflow: 'hidden'
				}}
			/>
		</div>
	);
};
